<template>
	<modal-view title="Delete Agent Confirmation" @close="$emit('close')" class="form">
		<div v-if="error" class="critical-error">{{error}}</div>
		<div v-else-if="deleting" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">
			The agent has been deleted successfully.
		</div>
		<div v-else>
			<p>You are about to delete the agent <span id="agent-name">{{agent.agent_name}}</span>. This cannot be undone. Are you sure you want to continue?</p>
			<button type="button" class="danger" @click="deleteAgent">Delete Agent</button>
			<button type="button" @click="$emit('close')">Cancel</button>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			agent: Object
		},
		data() {
			return {
				deleting: false,
				completed: false,
				error: null
			}
		},
		components: {
			ModalView
		},
		methods: {
			async deleteAgent() { // Performs the API request to delete the given agent.
				// Replace the confirmation message with a loading indicator.
				this.deleting = true;
				
				// Perform the API request to delete the given agent.
				try {
					await this.HTTP.delete('agents/' + this.agent.id);
					
					// If the agent was deleted successfully, display the success message and instruct the parent component to reload the agent list.
					this.completed = true;
					this.$emit('completed');
				} catch(error) { // If there was an error deleting the agent, display an error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The given agent is in use and can't be deleted.") { // If the error is that the agent is in use, display the specific error message from the API response.
						this.error = error.response.data.error;
					} else { // For any other error, display a generic error message.
						this.error = 'An error has occurred.';
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.deleting = false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#agent-name {
		font-weight:bold;
	}
	
	button {
		margin-right:20px;
		min-width:100px;
	}
</style>
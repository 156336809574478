<template>
	<modal-view :title="creatingAgent ? 'Create Agent' : 'Edit Agent'" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingAgent || loadingAgent" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="agentSaved" class="success-message">Agent {{creatingAgent ? 'Created' : 'Updated'}} Successfully</div>
		<div v-else>
			<form class="data-form" @submit.prevent="saveAgent">
				<div v-if="canManageInternalUsers && canManageCorporateCustomers">
					<label for="agent-type">Agent Type</label>
					<span class="required">*</span>
					<select id="agent-type" v-model="agentType" @change="loadAssignmentEntities">
						<option value="" disabled>Select...</option>
						<option value="customer">Customer</option>
						<option value="user">User</option>
					</select>
				</div>
				<div v-show="agentType == 'customer'">
					<div v-if="customerList === null" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<template v-else>
						<label for="assigned-customer-selection">Assigned Customer</label>
						<span class="required">*</span>
						<v-select id="assigned-customer-selection" v-model="assignedCustomerId" :options="formatCustomerListForDropdown()" :reduce="customer => customer.id" :selectable="customer => (customer.id !== null)" :clearable="false">
							<template v-slot:no-options>No matching customers found.</template>
						</v-select>
					</template>
				</div>
				<div v-show="agentType == 'user'">
					<div v-if="userList === null" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<template v-else>
						<label for="assigned-user-selection">Assigned User</label>
						<span class="required">*</span>
						<v-select id="assigned-user-selection" v-model="assignedUserId" :options="formatUserListForDropdown()" :reduce="user => user.id" :selectable="user => (user.id !== null)" :clearable="false">
							<template v-slot:no-options>No matching users found.</template>
						</v-select>
					</template>
				</div>
				<div id="provider-agent-ids">
					<h3>Provider Agent IDs</h3>
					<div>
						<label for="provider-selection">Add Provider</label>
						<select id="provider-selection" v-model="addProviderSelection" @change="addProviderAccountId">
							<option value="" disabled>Select...</option>
							<option value="OctaneCorporate" :disabled="providerAgentIds.OctaneCorporate">Telco in a Box Corporate</option>
							<option value="OctaneCorporateLegacy" :disabled="providerAgentIds.OctaneCorporateLegacy">Telco in a Box Corporate Legacy</option>
							<option value="OctaneResidential" :disabled="providerAgentIds.OctaneResidential">Telco in a Box Residential</option>
						</select>
					</div>
					<div v-for="provider in Object.keys(providerAgentIds)">
						<label :for="`provider-id-${provider}`">{{formatProvider(provider)}}</label>
						<input type="text" :id="`provider-id-${provider}`" v-model="providerAgentIds[provider]" @input="errorMessage = null" />
					</div>
				</div>
				<div class="button-wrapper">
					<button type="submit">{{creatingAgent ? 'Create' : 'Save'}} Agent</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import vSelect from 'vue-select';
	import {mapState, mapGetters} from 'vuex';
	
	export default {
		props: {
			agentId: String
		},
		data() {
			return {
				agent: null,
				agentType: '',
				assignedCustomerId: null,
				assignedUserId: null,
				providerAgentIds: {},
				customerList: null,
				userList: null,
				addProviderSelection: '',
				savingAgent: false,
				agentSaved: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			creatingAgent() { // Used to determine whether a new agent is being created, or an existing agent is being updated.
				return (this.agentId === null);
			},
			loadingAgent() { // Used to determine whether the updated agent details are being loaded from the API.
				return (!this.creatingAgent && this.agent === null);
			},
			canManageInternalUsers() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal')
			},
			canManageCorporateCustomers() { // Used to determine whether the authenticated user has the appropriate permission to manage corporate customers.
				return (this.user.customer_access == 'all' || this.user.customer_access == 'corporate');
			},
			...mapState(['user']),
			...mapGetters(['hasPermission'])
		},
		components: {
			ModalView, vSelect
		},
		async created() { // When the modal is loaded, check if we are editing an existing agent, and if so, populate the form with the existing agent details.
			if(!this.creatingAgent) {
				await this.getAgentDetails();
			} else { // When creating a new agent, set the Agent Type that is selected by default, if the authenticated user only has access to one agent type.
				this.setDefaultAgentType();
			}
		},
		methods: {
			async getAgentDetails() { // Performs the API request to get the agent details for the given agent.
				try {
					const response = await this.HTTP.get('agents/' + this.agentId);
					this.agent = response.data.data;
					
					this.agentType = this.agent.agent_type;
					this.providerAgentIds = this.agent.provider_agent_ids ?? {};
					
					await this.loadAssignmentEntities();
					switch(this.agentType) {
						case 'customer':
							this.assignedCustomerId = this.agent.record_id;
							break;
						case 'user':
							this.assignedUserId = this.agent.record_id;
							break;
					}
				} catch(error) { // If there was an error obtaining the agent details, display the generic error message.
					this.dataError = true;
				}
			},
			async loadAssignmentEntities() { // Loads the list of customers or users, depending on the value of the Agent Type dropdown menu, the first time the given value is selected.
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
				if(this.agentType == 'customer' && this.customerList === null) {
					await this.loadCustomerList();
				} else if(this.agentType == 'user' && this.userList === null) {
					await this.loadUserList();
				}
			},
			async loadCustomerList() { // Performs the API request to get the list of customers.
				try {
					const response = await this.HTTP.get('customers/corporate?limit=1000');
					this.customerList = response.data.data;
				} catch(error) { // If there was an error obtaining the customer list, display the generic error message.
					this.dataError = true;
				}
			},
			async loadUserList() { // Performs the API request to get the list of users.
				try {
					const response = await this.HTTP.get('users?access-type=internal&limit=1000');
					this.userList = response.data.data;
				} catch(error) { // If there was an error obtaining the user list, display the generic error message.
					this.dataError = true;
				}
			},
			addProviderAccountId() { // Adds a field to enter the Provider Agent ID for the provider selected from the "Add Provider" dropdown menu.
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
				this.providerAgentIds[this.addProviderSelection] = '';
				this.addProviderSelection = ''; // Clears the selection from the "Add Provider" dropdown menu.
			},
			async saveAgent() { // Performs the API request to create or update the given agent.
				if(this.validateForm()) {
					try {
						// Replace the edit agent form with a loading indicator.
						this.savingAgent = true;
						
						// Set the data to create or update the agent.
						const data = {provider_agent_ids: this.providerAgentIds};
						if(this.canManageInternalUsers && this.canManageCorporateCustomers) {
							data.agent_type = this.agentType;
						}
						switch(this.agentType) {
							case 'customer':
								data.record_id = this.assignedCustomerId;
								break;
							case 'user':
								data.record_id = this.assignedUserId;
								break;
						}
						
						// Perform the API request to create or update the given agent.
						(this.creatingAgent) ? await this.createAgent(data) : await this.updateAgent(data);
						
						// If the agent was updated successfully, display the success message and instruct the parent component to reload the agent list.
						this.agentSaved = true;
						this.$emit('completed');
					} catch(error) { // If there was an error saving the agent, display an error message below the edit agent form.
						if(error.response && error.response.status == 400 && error.response.data && (error.response.data.error == "The given customer has already been assigned to an agent." || error.response.data.error == "The given user has already been assigned to an agent.")) { // If the error is that the selected customer or user is already assigned to an agent, display the specific error message from the API response.
							this.errorMessage = error.response.data.error.replace('given', 'selected');
						} else { // For any other error, display a generic error message.
							this.errorMessage = 'An error has occurred.';
						}
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingAgent = false;
					}
				}
			},
			async createAgent(data) { // Performs the API request to create an agent with the given data.
				await this.HTTP.post('agents', data);
			},
			async updateAgent(data) { // Performs the API request to update the given agent with the given data.
				await this.HTTP.put('agents/' + this.agent.id, data);
			},
			validateForm() { // Validates the data provided in the form.
				// Set the list of required fields.
				const requiredFields = {'agent type': this.agentType}
				switch(this.agentType) {
					case 'customer':
						requiredFields['assigned customer'] = this.assignedCustomerId;
						break;
					case 'user':
						requiredFields['assigned user'] = this.assignedUserId;
						break;
				}
				
				// Ensure that all required fields in the agent form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '' || value == null) {
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
				}
				
				return true;
			},
			setDefaultAgentType() { // Sets the Agent Type that is selected by default, if the authenticated user only has access to one agent type.
				if(!this.canManageInternalUsers) {
					this.agentType = 'customer';
					this.loadAssignmentEntities();
				} else if(!this.canManageCorporateCustomers) {
					this.agentType = 'user';
					this.loadAssignmentEntities();
				}
			},
			formatCustomerListForDropdown() { // Returns the list of customers formatted for use in the customer list dropdown menu.
				const customerList = this.customerList.map(function(customer){
					return {id: customer.id, label: customer.company_details.company_name}
				});
				customerList.unshift({id: null, label: 'Select...'});
				return customerList;
			},
			formatUserListForDropdown() { // Returns the list of users formatted for use in the user list dropdown menu.
				const userList = this.userList.map(function(user){
					return {id: user.id, label: `${user.first_name} ${user.last_name}`.trim()}
				});
				userList.unshift({id: null, label: 'Select...'});
				return userList;
			},
			formatProvider(provider) { // Given a provider name, returns the user-friendly representation.
				switch(provider) {
					case 'OctaneCorporate':
						return 'Telco in a Box Corporate';
					case 'OctaneCorporateLegacy':
						return 'Telco in a Box Corporate Legacy';
					case 'OctaneResidential':
						return 'Telco in a Box Residential';
					default:
						return provider;
				}
			}
		},
		watch: { // When a customer or user is selected to assign to the agent, we need to clear any error messages, just like we do for any other field.
			assignedCustomerId() {
				this.errorMessage = null;
			},
			assignedUserId() {
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input, select {
			width:100%;
		}
	}
	
	#provider-agent-ids h3 {
		margin-top:50px;
		text-align:center;
	}
</style>
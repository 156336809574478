<template>
	<h1>Manage Agents</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="agents === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="agent-list-wrapper">
			<div id="filters" class="form">
				<div v-if="showAgentTypeFilter">
					<label for="agent-type-selection">Agent Type</label>
					<select id="agent-type-selection" v-model="displayAgentType" @change="loadAgents(true, true)">
						<option value="all">All</option>
						<option value="customer">Customer</option>
						<option value="user">User</option>
					</select>
				</div>
				<button v-if="canCreate" type="button" @click="createAgent">Create New Agent</button>
			</div>
			<div v-show="loadingAgents" class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<div id="agent-list" :class="{hidden: loadingAgents}">
				<div v-if="agents.length == 0" class="no-agents">
					There are no agents to display.
				</div>
				<table v-else class="data-table">
					<tr>
						<th>Agent Type</th>
						<th>Agent Name</th>
						<th v-if="canEdit || canDelete" :colspan="(canEdit && canDelete) ? 2 : 1">{{(canEdit && canDelete) ? 'Actions' : 'Edit'}}</th>
					</tr>
					<tr v-for="(agent, index) in agents">
						<td>{{capitaliseFirstLetter(agent.agent_type)}}</td>
						<td>{{agent.agent_name}}</td>
						<td v-if="canEdit" class="edit-cell" @click="editAgent(agent)" title="Edit Agent">&#x1F589;</td>
						<td v-if="canDelete" class="delete-cell" @click="deleteAgent(agent)" title="Delete Agent">X</td>
					</tr>
				</table>
				<pagination v-model="displayPage" :data="agents" @page-changed="loadAgents"></pagination>
			</div>
		</div>
		<edit-agent-form v-if="editingAgent" :agent-id="editedAgent" @close="editingAgent = false" @completed="loadAgents(false)"></edit-agent-form>
		<delete-agent-confirmation v-if="deletingAgent" :agent="deletingAgent" @close="deletingAgent = null" @completed="loadAgents(false)"></delete-agent-confirmation>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import EditAgentForm from '@/components/Modals/Agents/EditAgentForm';
	import DeleteAgentConfirmation from '@/components/Modals/Agents/DeleteAgentConfirmation';
	import {mapState, mapGetters} from 'vuex';
	
	export default {
		data() {
			return {
				agents: null,
				displayAgentType: 'all',
				loadingAgents: false,
				dataError: false,
				editingAgent: false,
				editedAgent: null,
				deletingAgent: null,
				displayPage: 1
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create agents.
				return this.hasPermission('agents', 'create');
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit agents.
				return this.hasPermission('agents', 'edit');
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete agents.
				return this.hasPermission('agents', 'delete');
			},
			canManageInternalUsers() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal')
			},
			canManageCorporateCustomers() { // Used to determine whether the authenticated user has the appropriate permission to manage corporate customers.
				return (this.user.customer_access == 'all' || this.user.customer_access == 'corporate');
			},
			showAgentTypeFilter() { // Used to determine whether to display the agent type filter. This is only displayed if the authenticated user has access to both agent types.
				return (this.canManageInternalUsers && this.canManageCorporateCustomers);
			},
			...mapState(['user']),
			...mapGetters(['hasPermission'])
		},
		components: {
			Pagination, EditAgentForm, DeleteAgentConfirmation
		},
		async created() { // When the page is loaded, get the list of agents.
			await this.loadAgents();
		},
		methods: {
			async loadAgents(refresh = true, resetPagination = false) { // Performs the API request to get the list of agents.
				// If the pagination needs to be reset, set the displayed page to Page 1.
				if(resetPagination) {
					this.displayPage = 1;
				}
				
				// If we're visually refreshing the page (rather than stealthily updating the agent list), display the loading indicator.
				if(refresh) {
					this.loadingAgents = true;
				}
				
				// Perform the API request to get the list of agents.
				try {
					const queryString = (this.displayAgentType != 'all') ? `&agent-type=${this.displayAgentType}` : ''; // Sets the query string argument to filter the agent list to the appropriate agent type. If no filter should be applied, the query string is omitted.
					const response = await this.HTTP.get(`agents?page=${this.displayPage}` + queryString);
					this.agents = response.data.data;
				} catch(error) { // If there was an error obtaining the agent list, display the generic error message.
					this.dataError = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.loadingAgents = false;
				}
			},
			createAgent() { // Displays the modal for creating a new agent.
				this.editedAgent = null;
				this.editingAgent = true;
			},
			editAgent(agent) { // Displays the edit agent modal for the agent at the given index in the agents array.
				this.editedAgent = agent.id;
				this.editingAgent = true;
			},
			deleteAgent(agent) { // Displays the deletion confirmation modal for the agent at the given index in the agents array.
				this.deletingAgent = agent;
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			}
		}
	}
</script>

<style scoped lang="scss">
	#agent-list-wrapper {
		width:max-content;
		margin:0 auto;
	}
	
	#agent-list.hidden {
		visibility:hidden;
	}
	
	#filters {
		text-align:right;
		margin-bottom:20px;
		
		div {
			text-align:left;
			display:inline-block;
			margin-bottom:0;
		}
		
		label {
			display:block;
		}
		
		div, button {
			margin-left:20px;
		}
	}
	
	.no-agents {
		text-align:center;
	}
</style>